/* Container */
.token-info-page-container {
  text-align: center;
  color: #ecf0f1;
  padding: 20px;
}

/* Back Link */
.token-info-page-back-link {
  text-align: left;
  margin-bottom: 20px;
}

/* Input Container */
.token-info-page-input-container {
  display: flex;
  flex-direction: column; /* Ensure input and button are stacked vertically */
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

/* Input */
.token-info-page-input {
  max-width: 500px; /* Set max-width for the input box */
  width: 100%; /* Allow it to shrink to fit within the max-width */
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background-color: #2c3e50;
  color: #ecf0f1;
}

/* Button */
.token-info-page-btn-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ecf0f1; /* Lighter text color */
  text-decoration: none; /* Remove underline */
  background-color: #3498db; /* Brighter button color */
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin: 10px;
  cursor: pointer;
}

.token-info-page-btn-link:hover {
  background-color: #2980b9; /* Darker hover color */
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3); /* Inner shadow on hover */
}

/* Error Message */
.token-info-page-error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

/* Info Container */
.token-info-page-info-container {
  margin-top: 20px;
}

/* Owner Info */
.token-info-page-owner {
  font-size: 18px;
  margin-bottom: 10px;
}

.token-info-page-owner-link {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.token-info-page-owner-link:hover {
  color: #2980b9;
}

/* Image */
.token-info-page-image {
  max-width: 300px;
  margin-top: 20px;
  border: 2px solid #3498db;
  border-radius: 5px;
}

/* Traits */
.token-info-page-traits-title {
  font-size: 20px;
  margin-top: 20px;
}

.token-info-page-traits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.token-info-page-trait-item {
  background-color: #34495e;
  padding: 10px 15px;
  margin: 5px;
  border-radius: 5px;
  color: #ecf0f1;
  width: 45%;
  box-sizing: border-box;
}

.token-info-page-trait-type {
  font-weight: bold;
}

.token-info-page-note {
  color: #bdc3c7;
  font-style: italic;
  margin-top: 5px;
  font-size: 14px;
}

/* Footer Specific to TokenInfoPage */
.token-info-page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #34495e; /* Slightly different color from the main background */
  color: #ecf0f1;
  text-align: center;
  padding: 10px 0;
  margin: 0; /* Ensure no margin */
  left: 0; /* Ensure footer is aligned to the left */
  right: 0; /* Ensure footer is aligned to the right */
  box-sizing: border-box; /* Include padding and border in the element's total width and height */
  z-index: 1000; /* Ensure footer is on top of other elements */
}

.token-info-page-links-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0; /* Ensure no margin */
  padding: 0; /* Ensure no padding */
  width: 100%; /* Ensure full width */
}

.token-info-page-links-container p {
  margin: 0;
}

.token-info-page-btn-link-footer {
  color: #ecf0f1;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.token-info-page-btn-link-footer:hover {
  text-decoration: underline;
}

.token-info-page-social-icon {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

/* Media Queries */
@media (max-width: 768px) {
  .token-info-page-container {
    padding: 10px;
  }

  .token-info-page-input-container {
    flex-direction: column;
    gap: 5px;
  }

  .token-info-page-input {
    padding: 8px;
    font-size: 14px;
  }

  .token-info-page-owner {
    font-size: 16px;
  }

  .token-info-page-owner-link {
    font-size: 14px;
  }

  .token-info-page-image {
    max-width: 250px;
  }

  .token-info-page-traits-title {
    font-size: 18px;
  }

  .token-info-page-traits-container {
    flex-direction: column;
    align-items: center;
  }

  .token-info-page-trait-item {
    width: 90%;
    max-width: 250px;
    padding: 8px 10px;
    font-size: 14px;
  }

  .token-info-page-btn-link {
    font-size: 14px;
    padding: 8px 15px;
  }

  .token-info-page-error-message {
    font-size: 14px;
  }

  /* Footer */
  .token-info-page-btn-link-footer {
    flex-direction: column;
  }

  .token-info-page-btn-link-footer span {
    display: none;
  }

  .token-info-page-social-icon {
    margin-right: 0;
  }
}
